import React, { useState } from "react";
import styled from "styled-components";
import { theme } from "../../../config/colorTheme";
import { animationKeyFrames } from "../../../config/keyframes";
import { devices } from "../../../devices";

const Container = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: ${(props) => (props.expand ? "50vw" : "0vw")};
  height: ${(props) => (props.expand ? "35vw" : "0vw")};
  background-color: ${theme.accentColor};
  transition: height 0.5s, width 0.5s;
  border-radius: 2vw;

  @media ${devices.mobile} {
    width: ${(props) => (props.expand ? "90vw" : "0vw")};
    height: ${(props) => (props.expand ? "120vw" : "0vw")};
  }
`;

const Icon = styled.img`
  float: right;
  width: 4vw;
  height: 4vw;
  cursor: pointer;

  @media ${devices.mobile} {
    width: 12vw;
    height: 12vw;
  }
`;

const AnimatingIcon = styled.img`
  float: right;
  width: 4vw;
  height: 4vw;
  cursor: pointer;
  animation: ${animationKeyFrames.heartBeat} 1.5s linear infinite alternate;

  @media ${devices.mobile} {
    width: 12vw;
    height: 12vw;
  }
`;

const Body = styled.div`
  animation: ${animationKeyFrames.fadeIn} 1s;
`;

export default function Prompt(props) {
  const [expand, setExpand] = useState(0);
  const [hintShown, setHintShown] = useState(0);
  const [transitionEnd, setTransitionEnd] = useState(0);
  return (
    <Container expand={expand} onTransitionEnd={() => setTransitionEnd(true)}>
      {expand || hintShown ? (
        <Icon
          onClick={() => {
            setExpand(!expand);
          }}
          onMouseEnter={() => {
            setExpand(true);
            setHintShown(true);
          }}
          src={
            expand
              ? require("./assets/remove_icon.svg")
              : require("./assets/help_icon.svg")
          }
          expand={expand}
        ></Icon>
      ) : (
        <AnimatingIcon
          onMouseEnter={() => {
            setExpand(true);
            setHintShown(true);
          }}
          onClick={() => {
            setExpand(true);
            setHintShown(true);
          }}
          src={require("./assets/help_icon.svg")}
        ></AnimatingIcon>
      )}
      <Body>{expand && transitionEnd ? props.body : null}</Body>
    </Container>
  );
}
