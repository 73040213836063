import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../../colorTheme";
import Drone from "../drone/Drone";
import DroneStory from "../drone/DroneStory";

const DroneContainer = styled.div`
  width: 28vw;
  height: 22vw;
  padding: 1.8vw;
  padding-bottom: 2vw;
  overflow: hidden;
  border: 2px solid ${theme.secondaryColor};
  border-radius: 1vw;
  margin: 2vw;
  transition: transform 0.3s, box-shadow 0.3s;
  background-color: #ffffff;
  cursor: pointer;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.25);
  &:hover {
    transform: scale(1.1);
    box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.25);
  }
`;

const Name = styled.p`
  font-size: 2vw;
  margin: 0;
  font-family: "Roboto";
  font-weight: 700;
  text-align: center;
  text-transform: capitalize;
`;

const Story = styled.p`
  font-size: 1.2vw;
  margin: 3vh 0;
  font-family: "Roboto Slab";
  font-weight: 300;
  text-align: left;
  line-height: 2vw;
`;

const DroneDetailsWrapper = styled.div`
  position: absolute;
  top: 2vw;
  left: 2vh;
  width: 94vw;
  height: auto;
  overflow: scroll;
  background-color: white;
  border: 3px solid ${theme.secondaryColor};
  border-radius: 2vw;
  margin-bottom: 15vw;
  padding-bottom: 5vw;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 2vw;
  right: 2vw;
`;

const DroneWrapper = styled.div`
  height: auto;
`;

const DroneStroryWrpper = styled.div`
  width: 100%;
`;

const ReactionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 85vw;
  margin: auto;
`;
const Reaction = styled.div`
  border: 2px solid ${(props) => props.color};
  width: 25vw;
  height: 20vw;
  overflow: scroll;
  margin: 1vw;
  padding: 1vw;
  border-radius: 1vw;
  align-items: stretch;
`;

const ReactionText = styled.p`
  font-size: 1.5vw;
  font-family: "Nunito";
  font-weight: 400;
  margin: auto;
`;

class DroneWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drone: null,
      selected: false,
    };
  }
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.id !== prevProps.id) {
      this.getDrone();
    }
  }

  componentDidMount() {
    this.getDrone();
  }

  getDrone() {
    fetch("/drones/" + this.props.id, {
      method: "GET",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Mode: "CORS",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ drone: data });
      });
  }

  renderReaction(reaction) {
    if (!reaction.why && !reaction.cons) return null;
    return (
      <React.Fragment>
        {reaction.why && (
          <Reaction
            color={
              reaction.selection == 0
                ? "green"
                : reaction.selection == 1
                ? "yellow"
                : "red"
            }
          >
            <ReactionText>{reaction.why}</ReactionText>
          </Reaction>
        )}
        {reaction.cons && (
          <Reaction color={"orange"}>
            <ReactionText>{reaction.cons}</ReactionText>
          </Reaction>
        )}
      </React.Fragment>
    );
  }

  render() {
    if (this.state.drone == null) return null;
    let item = this.state.drone;
    return (
      <div
        onClick={(e) =>
          !this.state.selected && this.setState({ selected: true })
        }
      >
        <DroneContainer>
          <Name>{item.name}</Name>
          <Story>
            {item.mainStory.text.length > 250
              ? item.mainStory.text.substring(0, 250) + " ..."
              : item.mainStory.text}
          </Story>
        </DroneContainer>
        {this.state.selected && (
          <DroneDetailsWrapper>
            <CloseButton onClick={(e) => this.setState({ selected: false })}>
              Close
            </CloseButton>
            <DroneStroryWrpper>
              <DroneWrapper>
                <Drone drone={item}></Drone>
              </DroneWrapper>
              <DroneStory
                name={item.name}
                story={item.mainStory ? item.mainStory.text : ""}
              ></DroneStory>
            </DroneStroryWrpper>
            <ReactionWrapper>
              {item.reactions.map((reaction) => {
                return this.renderReaction(reaction);
              })}
            </ReactionWrapper>
          </DroneDetailsWrapper>
        )}
      </div>
    );
  }
}

export default DroneWidget;
