import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../config/colorTheme";
import { devices } from "../devices";

import DroneStory from "./DroneStory";
import LoadingWidget from "./LoadingWidget";
import Button from "./commons/Button";

const ThankYouText = styled.h1`
  font-size: 5vw;
  margin: auto;
  margin-top: 15vh;
  font-family: "Roboto";
  font-weight: 900;
  color: ${theme.accentColor};

  @media ${devices.mobile} {
    font-size: 7vw;
  }
`;

const Message = styled.p`
font-size: 1.5vw;
 
  margin-bottom:1vh;
  margin-top:6vh;
  font-family: "Roboto";
  font-weight: 400;
  // color:${theme.primaryColor};
  text-align:center;

  @media ${devices.mobile} {
    font-size: 5vw;
  }
`;

const ButtonContainer = styled.div`
  margin: auto;
  width: 80vw;
  display: flex;
  padding: 5vw;
  justify-content: center;

  & > div {
    margin: 0 2vw;
    @media ${devices.mobile} {
      font-size: 5vw;
    }
  }
`;

export default class DoneView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drones: null,
    };
  }

  componentDidMount() {
    document.title = "Dream Drone";
    fetch("/random/drones/99")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ drones: data });
      });
  }

  render() {
    return (
      <div>
        <ThankYouText>Done! </ThankYouText>
        <Message>Thanks again for you time. </Message>
        {/* {this.state.drones == null && <LoadingWidget></LoadingWidget>}
        {this.state.drones != null && this.state.drones.map((item, key) => 
            !item.isFlagged && <DroneStory drone={item}></DroneStory>
          )
        } */}
        <ButtonContainer>
          <Button
            onClick={() => this.props.history.push("/allstories")}
            text="See what others have created"
          ></Button>
        </ButtonContainer>
      </div>
    );
    return null;
  }
}
