import React from "react";
import styled from "styled-components";
import { theme } from "../../config/colorTheme";
import { devices } from "../../devices";

const Container = styled.div`
  font-size: ${(props) => (props.small || props.secondary ? "1.5vw" : "2.2vw")};
  font-family: "Roboto", serif;
  font-weight: ${(props) => (props.secondary ? 400 : 900)};
  color: ${(props) => (props.secondary ? theme.accentColor : theme.white)};
  background-color: ${(props) =>
    props.secondary ? theme.white : theme.accentColor};
  border: 2px solid ${theme.accentColor};
  width: auto;
  padding: ${(props) => (props.small ? "0.4vw" : "1.5vw")};
  text-align: center;
  transition: transform 0.5s;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    transform: scale(1.05);
  }

  @media ${devices.mobile} {
    padding: ${(props) => (props.small ? "3vw" : "5vw")};
    font-size: ${(props) => (props.small || props.secondary ? "4vw" : "6vw")};
  }
`;

export default function Button(props) {
  return (
    <Container
      onClick={() => (props.onClick ? props.onClick() : null)}
      small={props.small}
      secondary={props.secondary}
    >
      {props.text}
    </Container>
  );
}
