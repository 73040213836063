import React, { Component } from "react";
import styled from "styled-components";
import LoadingWidget from "./LoadingWidget";
import { isGibberish } from "../utils.js";
import { devices } from "../config/devices.js";
import Header from "./commons/header/Header";

import { theme } from "../colorTheme";
import logo from "../drone.png";

const Wrapper = styled.div`
  width: 100%;
`;

const ContentWrapper = styled.div`
  position: relative;
  margin: auto;
  position: relative;
  height: auto;
  padding-bottom: 5vw;

  @media ${devices.mobile} {
    flex-direction: column;
    height: auto;
  }
`;

const DroneStory = styled.div`
  width: 28vw;
  height: 22vw;
  padding: 1.8vw;
  padding-bottom: 2vw;
  overflow: hidden;
  border: 2px solid ${theme.secondaryColor};
  border-radius: 1vw;
  margin: 2vw;
  transition: transform 0.3s, box-shadow 0.3s;
  background-color: #ffffff;
  cursor: pointer;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.25);
  &:hover {
    transform: scale(1.1);
    box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.25);
  }

  @media ${devices.mobile} {
    width: 90vw;
    height: auto;
    margin: 8vw auto;
  }
`;

const DroneStroryWrpper = styled.div`
  width: 100vw;
  margin: 5vw auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

const Name = styled.p`
  font-size: 2vw;
  margin: 0;
  font-family: "Roboto";
  font-weight: 700;
  text-align: center;
  text-transform: capitalize;

  @media ${devices.mobile} {
    font-size: 7vw;
  }
`;

const Story = styled.p`
  font-size: 1.2vw;
  margin: 3vh 0;
  font-family: "Roboto Slab";
  font-weight: 300;
  text-align: left;
  line-height: 2vw;

  @media ${devices.mobile} {
    font-size: 5vw;
    line-height: 7vw;
  }
`;

class AllDronesView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      droneSet: [],
    };
  }

  componentDidMount() {
    document.title = "Dream Drone";
    window.scrollTo(0, 0);
    var dronePool = [];
    fetch("/random/drones/500")
      .then((response) => response.json())
      .then((data) => {
        for (var i = 0; i < data.length; i++) {
          fetch("/drones/" + data[i]._id)
            .then((response) => response.json())
            .then((data) => {
              !data.isFlagged &&
                data.name &&
                !isGibberish(data.name, 5) &&
                this.setState({ droneSet: [...this.state.droneSet, data] });
            });
        }
      });
  }

  render() {
    if (!this.state.droneSet.length > 0) return <LoadingWidget></LoadingWidget>;
    return (
      <Wrapper>
        <Header text={"See and react to what other created"}></Header>
        <ContentWrapper>
          <DroneStroryWrpper>
            {this.state.droneSet.map((drone, index) => {
              return (
                <DroneStory
                  onClick={() =>
                    this.props.history.push("../seeandreact/" + drone._id)
                  }
                >
                  <Name>{drone.name}</Name>
                  <Story>
                    {drone.mainStory.text.length > 250
                      ? drone.mainStory.text.substring(0, 250) + " ..."
                      : drone.mainStory.text}
                  </Story>
                </DroneStory>
              );
            })}
          </DroneStroryWrpper>
        </ContentWrapper>

        {/* <RefreshButton onClick={() => { this.handleNextClick() }}>See a Different Drone</RefreshButton> */}
      </Wrapper>
    );
  }
}

export default AllDronesView;
