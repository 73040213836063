import React, { useEffect, useState } from "react";

import styled from "styled-components";

const Wrapper = styled.div`
  width: 80vw;
  margin: 10vw auto;
  display: flex;
  font-size: 1.3vw;
  font-family: "Roboto";
  font-weight: 700;
  align-items: center;
`;

const HalfWrapper = styled.div`
  width: 40vw;
  padding: 2vw;
`;

export default function TagCloud(props) {
  const [positives, setPositives] = useState([]);
  const [negatives, setNegatives] = useState([]);
  useEffect(() => {
    console.log(props);
    let t_negatives = [];
    let t_positives = [];
    props.stories.map(async (story) => {
      let [drone_negatives, drone_positives] = await getDrone(story.id);
      t_negatives = t_negatives.concat(drone_negatives);
      console.log(t_negatives);
      setNegatives(t_negatives);
      t_positives = t_positives.concat(drone_positives);
      setPositives(t_positives);
    });
  }, [props.stories]);

  const getDrone = async (id, t_negatives, t_positives) => {
    const response = await fetch("/drones/" + id, {
      method: "GET",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Mode: "CORS",
      },
    });
    const data = await response.json();
    return [data.tags.negatives, data.tags.positives];
  };

  return (
    <Wrapper>
      <HalfWrapper>
        {negatives.map((n) => {
          return <p>{n}</p>;
        })}
      </HalfWrapper>
      <HalfWrapper>
        {positives.map((n) => {
          return <p>{n}</p>;
        })}
      </HalfWrapper>
    </Wrapper>
  );
}
