import React from "react";
import styled, { keyframes } from "styled-components";
import { theme } from "../../config/colorTheme";

import { devices } from "../../devices";

const slideFromTop = keyframes`
0% {
    margin-top: 1vw;
    opacity:1;
}
70%{
    opacity:1;
}
100% {
    margin-top: 3vw;
    opacity:0;
}
`;

const Container = styled.div`
  color: ${theme.white};
  border: 2px solid ${theme.black};
  border-radius: 2vw;
  width: 3vw;
  height: 5vw;
  position: fixed;
  bottom: 3vw;
  right: 3vw;
  z-index: 200;

  &:hover {
    transform: scale(1.05);
  }

  @media ${devices.mobile} {
    width: 7vw;
    height: 15vw;
    background-color: white;
    border-radius: 4vw;
  }
`;

const Dot = styled.div`
  background-color: ${theme.black};
  border-radius: 5vw;
  width: 0.6vw;
  height: 0.6vw;
  margin: auto;
  margin-top: 1vw;
  z-index: 200;
  animation: ${slideFromTop} 1.3s infinite;

  @media ${devices.mobile} {
    width: 2vw;
    height: 2vw;
  }
`;

export default function ScrollDownMotionIcon(props) {
  return (
    <Container>
      <Dot></Dot>{" "}
    </Container>
  );
}
