import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import { split, Syntax } from "sentence-splitter";
import LoadingWidget from "../LoadingWidget";
import TextArea from "../commons/TextArea";
import DroneWidget from "./DroneWidget";
import TagCloud from "./TagCloud";

const SelectedStoryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 98vw;
  margin: auto;
  position: relative;
`;

const SearchWrapper = styled.div`
  margin: 5vw auto;
  width: 55vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ItemWrapper = styled.div`
  width: 30vw;
`;

const SearchButton = styled.button`
  width: 5vw;
  height: 2vw;
`;

class TestView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drones: null,
      searchText: "",
      selectedStories: [],
      processing: false,
    };
  }
  componentDidMount() {
    fetch("/drones/", {
      method: "GET",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Mode: "CORS",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ drones: data });
      });
  }

  async processAllStories() {
    let storyScores = [];
    for (let i = 0; i < this.state.drones.length; i++) {
      let drone = this.state.drones[i];
      if (!drone.isFlagged) {
        let story = drone.mainStory;
        if (story && !story.isFlagged) {
          let scores = await this.processStory(story.text);
          scores.length > 1 &&
            storyScores.push({
              id: drone._id,
              name: drone.name,
              score: scores[scores.length - 1],
              // + scores[scores.length - 2],
            });
          // console.log(JSON.stringify(scores));
        }
      }
    }
    // this.state.drones.map(async (drone) => {
    //   if (!drone.isFlagged) {
    //     let story = drone.mainStory;
    //     if (story && !story.isFlagged) {
    //       let scores = await this.processStory(story.text);
    //       console.log(scores);
    //     }
    //   }
    // });
    storyScores = storyScores.sort((a, b) => b.score - a.score);
    console.log(storyScores);

    let selectedStories = [];
    // if (storyScores[0].score > 0.1) {
    selectedStories.push(storyScores[0]);
    selectedStories.push(storyScores[1]);
    selectedStories.push(storyScores[storyScores.length - 1]);
    // for (let i = 1; i < storyScores.length - 1; i++) {
    //   // if (
    //   //   storyScores[i - 1].score - storyScores[i].score <=
    //   //   storyScores[i].score - storyScores[i + 1].score
    //   // ) {
    //   //   selectedStories.push(storyScores[i]);
    //   // } else break;

    //   if (i < 3) selectedStories.push(storyScores[i]);
    // }
    //}
    this.setState({ selectedStories: selectedStories, processing: false });

    console.log("_________________________");
    console.log(this.state.selectedStories);
  }

  async processStory(story) {
    this.setState({ processing: true });
    let sentences = split(story);
    let scores = [];
    for (let i = 0; i < sentences.length; i++) {
      let child = sentences[i];
      if (child && child.type == "Sentence") {
        let text = child.raw;
        //let embedding = await this.getEmbedding(text.replace(/\//g, " "));
        let similarity = await this.getCosSim(
          text.replace(/\//g, " "),
          this.state.searchText
        );
        console.log(text + similarity);
        scores.push(parseFloat(similarity));
      }
    }
    return scores.sort();
  }

  //   async processStory(story, scores) {
  //     let sentences = split(story);
  //     sentences.forEach(async (child) => {
  //       if (child && child.type == "Sentence") {
  //         let text = child.raw;
  //         //let embedding = await this.getEmbedding(text.replace(/\//g, " "));
  //         let similarity = await this.getCosSim(
  //           text.replace(/\//g, " "),
  //           "helps animals"
  //         );
  //         console.log(text + similarity);
  //         scores.push(similarity);
  //       }
  //     });
  //   }

  async getEmbedding(text) {
    let response = await fetch(
      "http://ec2-13-57-184-188.us-west-1.compute.amazonaws.com:8000/" + text,
      {
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      }
    );
    let data = await response.json();
    return data;
    console.log(data);
  }

  async getCosSim(text1, text2) {
    let response = await fetch(
      "http://ec2-13-57-184-188.us-west-1.compute.amazonaws.com:8000/cossim/",
      {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ text1: text1, text2: text2 }),
      }
    );
    let data = await response.json();
    return data;
  }

  updateDrone(body, id, key) {
    fetch("/drones/" + id, {
      method: "PUT",
      body: JSON.stringify(body),
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Mode: "CORS",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let drones = this.state.drones;
        drones[key].isFlagged = data.isFlagged;
        this.setState({ drones: drones });
        this.populateDroneCSV();
      });
  }

  render() {
    if (this.state.drones == null) return <LoadingWidget></LoadingWidget>;
    return (
      <div>
        <SearchWrapper>
          <h2>I'm designing a drone that </h2>

          <ItemWrapper>
            <TextArea
              value={this.state.searchText}
              onChangeCallback={(ev) =>
                this.setState({ searchText: ev.target.value })
              }
            ></TextArea>
          </ItemWrapper>
          <SearchButton onClick={() => this.processAllStories()}>
            Search
          </SearchButton>
        </SearchWrapper>
        {this.state.processing && <LoadingWidget></LoadingWidget>}
        <TagCloud stories={this.state.selectedStories}></TagCloud>
        <SelectedStoryWrapper>
          {this.state.selectedStories.map((story) => {
            return <DroneWidget id={story.id}></DroneWidget>;
          })}
        </SelectedStoryWrapper>
      </div>
    );
  }
}

export default TestView;
