import React, { Component } from "react";
import styled, { keyframes } from "styled-components";

import { theme } from "../colorTheme";
import { isGibberish } from "../utils.js";
import { runInThisContext } from "vm";

import Story from "./Data_Story";
import Feature from "./Data_Feature";
import TextArea from "./commons/TextArea";

// import {devices} from "../devices";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  height: 95vh;
  width: 100vw;
`;

const LeftWrapper = styled.div`
  margin: auto 1vw;
  padding: 2vw;
  // border-radius:2vw;
  // border: solid 1px ${theme.primaryColor};

  height: 95vh;
  width: 30vw;
  overflow: scroll;
`;

const RightWrapper = styled.div`
  margin: auto 1vw;
  padding: 1vw;
  border-radius: 2vw;
  border: solid 1px ${theme.primaryColor};

  height: 95vh;
  width: 68vw;

  overflow: scroll;
`;

const DroneListItem = styled.div`
  margin: 1vw;
  padding: 1vw;
  border-radius: 0.5vw;
  border: solid 1px ${theme.primaryColor};

  &:hover {
    transform: scale(1.04);
  }
`;

const ToggleButton = styled.div`
  background-color: ${(props) => (props.flagged ? "red" : "green")};
`;

const Reaction = styled.div`
  border: 5px solid ${(props) => props.color};
  width: 30vw;
  margin: 2vw;
  padding: 1vw;
  border-radius: 0.8vw;
`;

class Data_Drone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFlagged: this.props.drone.isFlagged,
      tags: this.props.drone.tags,
      positives: this.getPositiveString(),
      negatives: this.getNegativeString(),
    };
  }

  componentDidUpdate(prevProps) {
    //this.setState({isFlagged:this.props.drone.isFlagged})
    if (this.props.drone._id != prevProps.drone._id) {
      this.setState({
        positives: this.getPositiveString(),
        negatives: this.getNegativeString(),
      });
    }
  }

  updateDrone(body) {
    fetch("/drones/" + this.props.drone._id, {
      method: "PUT",
      body: JSON.stringify(body),
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Mode: "CORS",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ isFlagged: data.isFlagged });
        this.props.drone.isFlagged = data.isFlagged;
      });
  }

  renderReaction(reaction) {
    if (!reaction.why && !reaction.cons) return null;
    return (
      <div>
        {reaction.why && (
          <Reaction
            color={
              reaction.selection == 0
                ? "green"
                : reaction.selection == 1
                ? "yellow"
                : "red"
            }
          >
            {reaction.why}
          </Reaction>
        )}
        {reaction.cons && <Reaction color={"orange"}>{reaction.cons}</Reaction>}
      </div>
    );
  }

  updatePositives(ev) {
    let text = ev.target.value;
    this.setState({ positives: text });
    if (text) {
      let positiveTags = text.split(",");
      let body = this.state.tags;
      body.positives = positiveTags;
      console.log(body);
      this.updateDrone({ tags: body });
    }
  }

  updateNegatives(ev) {
    let text = ev.target.value;
    this.setState({ negatives: text });
    if (text) {
      let negativeTags = text.split(",");
      let body = this.state.tags;
      body.negatives = negativeTags;
      console.log(body);
      this.updateDrone({ tags: body });
    }
  }

  getPositiveString() {
    let positives = "";
    let i = 0;
    for (i = 0; i < this.props.drone.tags.positives.length; i++) {
      positives += this.props.drone.tags.positives[i];
      if (i < this.props.drone.tags.positives.length - 1) {
        positives += ",";
      }
    }
    return positives;
  }

  getNegativeString() {
    let negatives = "";
    let i = 0;
    for (i = 0; i < this.props.drone.tags.negatives.length; i++) {
      negatives += this.props.drone.tags.negatives[i];
      if (i < this.props.drone.tags.negatives.length - 1) {
        negatives += ",";
      }
    }
    return negatives;
  }

  render() {
    return (
      <div>
        <h2>{this.props.drone.name}</h2>
        {/* <p>{JSON.stringify(this.props.drone.createdBy)}</p> */}
        {/* <ToggleButton 
        flagged={this.props.drone.isFlagged}>{this.props.drone.isFlagged? 'Containes Gibberish' : 'No Gibberish'}</ToggleButton> */}
        {this.props.drone.features.map((item, key) => (
          // <p><strong>{item.title}</strong> : {item.description}</p>
          <Feature feature={item}></Feature>
        ))}
        {/* <p>{this.props.drone.mainStory && this.props.drone.mainStory.text}</p> */}
        {this.props.drone.mainStory && (
          <Story
            filter={this.props.filter}
            story={this.props.drone.mainStory}
          ></Story>
        )}

        {this.props.drone.subStories.map((item, key) => (
          <Story filter={this.props.filter} story={item}></Story>
        ))}

        {this.props.drone.reactions.map((reaction) => {
          return this.renderReaction(reaction);
        })}
        {/* <p>{JSON.stringify(this.props.drone.reactions)}</p> */}
        <h2>Positive tags</h2>
        <TextArea
          onChangeCallback={(ev) => this.updatePositives(ev)}
          value={this.state.positives}
        ></TextArea>
        <h2>Negative tags</h2>
        <TextArea
          onChangeCallback={(ev) => this.updateNegatives(ev)}
          value={this.state.negatives}
        ></TextArea>
      </div>
    );
  }
}

export default Data_Drone;
