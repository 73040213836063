import React, { Component } from "react";
import styled from "styled-components";
import LoadingWidget from "./LoadingWidget";
import { isGibberish } from "../utils.js";
import { devices } from "../config/devices.js";
import Header from "./commons/header/Header";
import Drone from "./drone/Drone";
import DroneStory from "./drone/DroneStory";
import DroneReactions from "./drone/DroneReactions";

import { theme } from "../colorTheme";
import logo from "../drone.png";

const Wrapper = styled.div`
  width: 100%;
`;

const ContentWrapper = styled.div`
  position: relative;
  margin: auto;
  position: relative;
  height: auto;
  padding-bottom: 5vw;

  @media ${devices.mobile} {
    flex-direction: column;
    height: auto;
  }
`;

const DroneWrapper = styled.div`
  height: 25vw;
`;

const DroneStroryWrpper = styled.div`
  width: 100%;
`;

const Dot = styled.div`
  width: 1vw;
  height: 1vw;
  background-color: ${(props) =>
    props.selected ? theme.secondaryColor : "#DCDCDC"};
  border-radius: 5vw;

  @media ${devices.mobile} {
    width: 2vw;
    height: 2vw;
  }
`;

const STORY_PLACEHOLDER = "Add your story";

const STORY_PROMPT =
  "Add your own story about this drone. Where does it fly? What does it do there and why?";

const DRONE_SET_SIZE = 1;
const VISIBLE_DRONES_COUNT = 1;

const LEFT_FACTOR = 26;
const TOP_FACTOR = 13;
const LEFT_0 = 6.5;
const TOP_0 = 6.5;

var dronePool = [];

class SeeAndReactView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drone: null,
      reactionState: false,
    };
  }

  componentDidMount() {
    document.title = "Dream Drone";
    window.scrollTo(0, 0);

    //fetch the drone data using id
    fetch("/drones/" + this.props.match.params.id)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ drone: data });
      });
  }

  render() {
    if (this.state.drone == null) return <LoadingWidget></LoadingWidget>;
    return (
      <Wrapper>
        <Header
          text={"What do you think about someone else’s dream drone"}
          disableDone={!this.state.reactionState}
          onDoneCallback={() => {
            if (!this.state.reactionState) return;
            alert("Successfully saved!");
            this.props.history.push("../allstories");
          }}
        ></Header>
        <ContentWrapper>
          <div>
            <DroneStroryWrpper>
              <DroneWrapper>
                <Drone drone={this.state.drone}></Drone>
              </DroneWrapper>
              <DroneStory
                name={this.state.drone.name}
                story={
                  this.state.drone.mainStory
                    ? this.state.drone.mainStory.text
                    : ""
                }
              ></DroneStory>
            </DroneStroryWrpper>
            <DroneReactions
              onReactionUpdate={(reactionState) => {
                this.setState({ reactionState: reactionState });
              }}
              drone={this.state.drone}
            ></DroneReactions>
          </div>
        </ContentWrapper>

        {/* <RefreshButton onClick={() => { this.handleNextClick() }}>See a Different Drone</RefreshButton> */}
      </Wrapper>
    );
  }
}

export default SeeAndReactView;
