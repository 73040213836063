import React from "react";
import styled from "styled-components";
import { theme } from "../../config/colorTheme";
import { devices } from "../../devices";

const Text = styled.p`
  font-size: 1.8vw;
  font-family: "Roboto", serif;
  font-weight: 700;
  color: ${theme.black};
  width: 100%;
  margin: 0;
  text-align: left;

  @media ${devices.mobile} {
    font-size: 5vw;
    width: 80vw;
    margin-top: 15vw;
  }
`;

export default function Prompt(props) {
  return <Text>{props.text}</Text>;
}
